<template>
  <div>
    <el-form
      :model="form"
      label-position="top"
    >
      <div class="flex flex-wrap -mx-2">
        <div class="w-1/2 px-2">
          <el-form-group
            name="Cluster Name"
            label="Cluster Name"
            :validator="$v.form.name"
          >
            <el-input
              v-model="form.name"
              placeholder="Cluster Name"
              @input="$v.form.name.$touch()"
            />
          </el-form-group>
        </div>
        <div class="w-1/2 px-2">
          <el-form-group
            name="Status"
            label="Status"
            :validator="$v.form.status"
          >
            <el-select
              v-model="form.status"
              class="w-full"
              placeholder="Select Status"
              @blur="$v.form.status.$touch()"
            >
              <el-option
                v-for="item in options.status"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-group>
        </div>
        <div class="w-full px-2">
          <el-form-group
            name="Cameras"
            label="Cameras"
            :validator="$v.form.cameras"
          >
            <el-select
              v-model="form.cameras"
              class="w-full"
              placeholder="Select Cameras"
              multiple
              filterable
              @blur="$v.form.cameras.$touch()"
            >
              <el-option
                v-for="item in options.cameras"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
                <span style="float: left">{{ item.label }} ({{ item.address }})</span>
              </el-option>
            </el-select>
          </el-form-group>
        </div>
      </div>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer flex justify-between"
    >
      <div>
        <el-button
          v-if="mode === 'update'"
          type="danger"
          @click="doDelete"
        >
          Delete
        </el-button>
      </div>
      <div>
        <el-button @click="closeDialog">
          Cancel
        </el-button>
        <el-button
          class="ml-auto"
          type="primary"
          :disabled="$v.$invalid"
          @click="doSave"
        >
          Save
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>

import { required } from "vuelidate/lib/validators";
const uuidv4 = require('uuid/v4');

export default {
  props: ['id'],
  data() {
    return {
      mode: 'create',
      data: {
        name: null,
        cameras_clusters: []
      },
      form: {
        id: null,
        name: '',
        status: 0,
        cameras: []
      },
      options: {
        users: [],
        status: [
          {
            label: 'Active',
            value: 1
          },
          {
            label: 'Inactive',
            value: 0
          },
          {
            label: 'Blocked',
            value: -1
          }
        ]
      }
    }
  },
  async created() {
    this.mode = this.$props.id ? 'update' : 'create';
  },
  async mounted() {
    const cameras = await this.getCamerasData();
    this.options.cameras = cameras.records.map(camera => {
      return {
        value: camera.id,
        label: camera.name,
        address: camera.address
      }
    })
    if(this.mode === 'create') {
      this.form.id = uuidv4();
    } else {
      this.data = await this.getData();
      const { id, name, status } = this.data;
      this.form.id = id;
      this.form.name = name;
      this.form.status = status;
      await this.data.cameras_clusters.forEach(item => {
        this.form.cameras.push(item.camera_id)
      });
      this.$v.$touch();
      this.$emit('loadingHandler', false);
    }
  },
  validations: {
    form: {
      name: { required },
      status: { required }
    }
  },
  methods: {
    getCamerasData() {
      return new Promise((resolve, reject) => {
        const searchParams = new URLSearchParams();
        searchParams.append('join', 'clusters');
        this.$request.get('records/cameras', { params: searchParams })
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            reject(err);
          })
      })
    },
    createCameraClusterMapping() {
      return new Promise((resolve, reject) => {
        // console.log(this.form);
        if(this.form.cameras.length > 0) {
          const data = [];
          this.form.cameras.forEach(camera => {
            data.push({
              id: uuidv4(),
              camera_id: camera,
              cluster_id: this.form.id,
            })
          });
          this.$request.post('records/cameras_clusters', data)
            .then(response => {
              resolve(response.data);
            })
            .catch(err => {
              this.$message({
                type: 'error',
                message: err.response.data.message
              });
              reject(err);
            })
        } else {
          resolve('SKIP');
        }
      })
    },
    createCluster() {
      return new Promise((resolve, reject) => {
        const data = {
          id: this.form.id,
          name: this.form.name,
          created_at: null,
          status: this.form.status,
        }
        this.$request.post('records/clusters', data)
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            this.$message({
              type: 'error',
              message: err.response.data.message
            });
            reject(err);
          })
      })
    },
    updateCluster() {
      return new Promise((resolve, reject) => {
        const data = {
          name: this.form.name,
          updated_at: null,
          status: this.form.status,
        }
        this.$request.put(`records/clusters/${this.form.id}`, data)
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            this.$message({
              type: 'error',
              message: err.response.data.message
            });
            reject(err);
          })
      })
    },
    deleteCluster() {
      return new Promise(async (resolve, reject) => {
        this.$request.delete(`records/clusters/${this.form.id}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            this.$message({
              type: 'error',
              message: err.response.data.message
            });
            reject(err);
          })
      })
    },
    async createData() {
      return new Promise(async (resolve) => {
        await this.createCluster();
        await this.createCameraClusterMapping();
        this.$store.dispatch('RELOAD', 'panel');
        resolve('DONE')
      })
    },
    deleteCameraClusterMapping(groupIds) {
      return new Promise(async (resolve, reject) => {
        this.$request.delete(`records/cameras_clusters/${groupIds.join(',')}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            this.$message({
              type: 'error',
              message: err.response.data.message
            });
            reject(err);
          })
      })
    },
    async updateData() {
      return new Promise(async (resolve) => {
        if(this.data.cameras_clusters.length > 0) {
          await this.deleteCameraClusterMapping(this.data.cameras_clusters.map(item => item.id));
        }
        await this.createCameraClusterMapping();
        await this.updateCluster();
        resolve('DONE')
      })
    },
    async deleteData() {
      return new Promise(async (resolve) => {
        if(this.data.cameras_clusters.length > 0) {
          await this.deleteCameraClusterMapping(this.data.cameras_clusters.map(item => item.id));
        }
        await this.deleteCluster();
        this.$store.dispatch('RELOAD', 'panel');
        resolve('DONE')
      })
    },
    getData() {
      return new Promise((resolve, reject) => {
        const searchParams = new URLSearchParams();
        searchParams.append('join', 'cameras_clusters');
        this.$request.get(`records/clusters/${this.$props.id}`, { params: searchParams })
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            reject(err);
          })
      })
    },
    closeDialog() {
      this.$emit('closeDialog');
    },
    async doSave() {
      this.$confirm('Are you sure?', 'Warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(async () => {
        this.$emit('loadingHandler', true);
        (this.mode === 'create') ? await this.createData() : await this.updateData();
        this.$emit('loadingHandler', false);
        this.$emit('closeDialog');
        this.$emit('renderData');
        this.$message({
          type: 'success',
          message: 'Operation Success'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Operation Cancelled'
        });          
      });
    },
    async doDelete() {
      this.$confirm('Are you sure?', 'Warning', {
        confirmButtonText: 'Delete',
        confirmButtonClass: 'el-button--danger',
        cancelButtonText: 'Cancel',
        type: 'error'
      }).then(async () => {
        this.$emit('loadingHandler', true);
        await this.deleteData();
        this.$emit('loadingHandler', false);
        this.$emit('closeDialog');
        this.$emit('renderData');
        this.$message({
          type: 'success',
          message: 'Operation Success'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Operation Cancelled'
        });          
      });
    }
  }
}
</script>

<style>

</style>