<template>
  <div
    v-if="loaded"
    class="flex flex-row"
  >
    <dl class="w-1/2">
      <dt>Cluster Name</dt>
      <dd>{{ data.name }}</dd>
      <dt>Status</dt>
      <dd>
        <el-tag
          size="mini"
          effect="dark"
          :type="parse.status[data.status].type"
        >
          {{ parse.status[data.status].label }}
        </el-tag>
      </dd>
    </dl>
    <dl class="w-1/2">
      <dt>Cameras</dt>
      <dd>
        <ul class="list-disc">
          <li
            v-for="(val, index) in data.cameras"
            :key="index"
            class="ml-4"
          >
            {{ val.name }}
          </li>
        </ul>
      </dd>
    </dl>
  </div>
</template>

<script>
export default {
  props: ['id'],
  data() {
    return {
      loaded: false,
      data: {},
      parse: {
        status: {
          '-1': {
            label: 'Blocked',
            type: 'danger'
          },
          '0': {
            label: 'Inactive',
            type: 'info'
          },
          '1': {
            label: 'Active',
            type: 'success'
          }
        }
      }
    }
  },
  async created() {
    this.data = await this.getData();
    this.loaded = true;
    this.$emit('loadingHandler', false);
  },
  methods: {
    getData() {
      return new Promise((resolve, reject) => {
        const searchParams = new URLSearchParams();
        searchParams.append('join', 'cameras');
        this.$request.get(`records/clusters/${this.$props.id}`, { params: searchParams })
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            reject(err);
          })
      })
    }
  }
}
</script>

<style scoped>
dt {
  @apply text-blue-500;
}

dd {
  @apply mb-4
}
</style>