<template>
  <div>
    <Table
      :key="key.table"
      @openDialog="openDialog"
    />
    <el-dialog
      :visible.sync="dialog.visible"
      :title="dialog.title"
      :before-close="beforeCloseHandler"
      :show-close="dialog.type === 'detail'"
      width="80%"
    >
      <div v-loading="dialog.loading">
        <Detail
          v-if="dialog.type === 'detail'"
          :id="dialog.id"
          :key="key.detail"
          @closeDialog="closeDialog"
          @loadingHandler="loadingHandler"
        />
        <Form
          v-if="dialog.type === 'create' || dialog.type === 'update'"
          :id="dialog.id"
          :key="key.form"
          @closeDialog="closeDialog"
          @loadingHandler="loadingHandler"
          @renderData="renderData"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>

import Table from './Clusters.table';
import Detail from './Clusters.detail';
import Form from './Clusters.form';
import { toTitleCase } from '../../../../utils/strings';

export default {
  components: {
    Table, Detail, Form
  },
  data() {
    return {
      key: {
        table: 0,
        dialog: 1,
        detail: 2,
        form: 3
      },
      dialog: {
        visible: false,
        title: 'Modal Title',
        type: 'create',
        id: null,
        loading: false
      }
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    doEdit() {
      this.modal.detail.open = false;
      this.modal.create.open = true;
      this.modal.create.data = this.modal.detail.data;
    },
    doDelete() {
      this.$confirm('Apakah Anda yakin akan menghapus data ini?', 'Warning', {
        confirmButtonText: 'Ya, hapus data ini',
        cancelButtonText: 'Batal',
        type: 'danger'
      }).then(() => {
        this.$message({
          type: 'warning',
          message: 'Data sukses dihapus'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Batal menghapus data.'
        });          
      });
    },
    renderData() {
      this.key.table++;
    },
    openDialog(val) {
      this.key.form++;
      if(val.type !== 'create') {
        this.dialog.loading = true;
        this.key.detail++;
        this.$store.dispatch('SET_DETAIL', val.data.id);
      }
      this.dialog.type = val.type;
      this.dialog.id = val.data ? val.data.id : null;
      this.dialog.title = toTitleCase(val.type) + ' Cluster';
      this.dialog.visible = true;
    },
    closeDialog() {
      this.dialog.visible = false;
    },
    beforeCloseHandler(done) {
      if(this.dialog.type === 'detail') {
        done();
      }
    },
    loadingHandler(val) {
      this.dialog.loading = val;
    },
  }
}
</script>